import { Button } from '@upper/sapphire/ui'
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  isSameDay,
  startOfDay,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import addDays from 'date-fns/addDays'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import format from 'date-fns/format'
import isWeekend from 'date-fns/isWeekend'
import startOfTomorrow from 'date-fns/startOfTomorrow'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { formatDateRange } from 'little-date'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarFoldIcon,
  TrashIcon,
} from 'lucide-react'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import timezones from 'timezones-list'
import { v4 as uuid } from 'uuid'
import { classNames } from './classnames'
import { Select } from './select'

export type TimePickerProps = {
  value: TimeBlock[]
  onChange: (value: TimeBlock[]) => void
}

const TIME_FRAGMENT = 0.25 // 15 minutes
const MINUTES_PER_TIME_FRAGMENT = 60 * TIME_FRAGMENT
const MINUTES_PER_TIME_FRAGMENT_STEP = 5
const PIXELS_PER_MINUTE = 2
const TOTAL_HOURS = 24
const START_HOUR = 0

const dayTopPadding = 1 // px

export function TimePicker({ value, onChange }: TimePickerProps) {
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [week, setWeek] = React.useState(0)
  const scrollContainerRef = useRef(null)
  const controls = useAnimation()

  const { daysList, timeIntervals } = React.useMemo(() => {
    const tomorrow = startOfTomorrow()
    const tomorrowPlus6Days = addDays(tomorrow, 6)
    return {
      daysList: eachDayOfInterval({
        start: addDays(tomorrow, week * 7),
        end: addDays(tomorrowPlus6Days, week * 7),
      }),
      timeIntervals: [...Array(1 + (TOTAL_HOURS - START_HOUR) * 4).keys()].map(
        (i) => addMinutes(tomorrow, START_HOUR * 60 + 60 * TIME_FRAGMENT * i)
      ),
    }
  }, [week])

  const addTimeBlock = useCallback(
    (timeBlock: TimeBlock) => {
      onChange([...value, timeBlock])
    },
    [onChange, value]
  )

  const removeTimeBlock = useCallback(
    (id: string) => {
      onChange(value.filter((timeBlock) => timeBlock.id !== id))
    },
    [onChange, value]
  )

  const updateTimeBlock = useCallback(
    (timeBlock: TimeBlock) => {
      onChange(value.map((tb) => (tb.id === timeBlock.id ? timeBlock : tb)))
    },
    [onChange, value]
  )

  const scrollToElement = useCallback((element: HTMLElement) => {
    const container: HTMLElement =
      scrollContainerRef.current as unknown as HTMLElement
    if (container && element) {
      container.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('schedule-time-08:00')
      if (element) {
        scrollToElement(element as HTMLElement)
      }
    }, 600)
  }, [scrollToElement])

  return (
    <div className="">
      <header className="border-b border-slate-200 bg-white">
        <div className="items-center justify-between p-3 md:flex md:px-3">
          <div className="">
            <Select
              className="w-full text-sm"
              value={selectedTimezone}
              onChange={(event) => {
                const newTimezone = event.target.value
                onChange(
                  value.map((timeBlock) => {
                    const zonedTime = utcToZonedTime(
                      timeBlock.date,
                      newTimezone
                    )
                    return {
                      ...timeBlock,
                      date: zonedTime,
                    }
                  })
                )
                setSelectedTimezone(newTimezone)
              }}
            >
              {timezones.map((timezone) => (
                <option key={timezone.tzCode} value={timezone.tzCode}>
                  GMT {timezone.utc}
                </option>
              ))}
            </Select>
          </div>
          <div className="text-gray-dark flex items-center gap-2 p-4 text-center text-sm leading-tight md:p-0">
            <Button
              size="icon"
              onClick={() => setWeek(0)}
              disabled={week === 0}
            >
              <CalendarFoldIcon size={16} />
            </Button>
            <Button
              size="icon"
              onClick={() => setWeek((w) => (w - 1 < 0 ? 0 : w - 1))}
              disabled={week === 0}
            >
              <ArrowLeftIcon size={16} />
            </Button>
            <span className="font-mono-chivo">
              {formatDateRange(daysList[0], daysList[6], {
                includeTime: false,
              })}
            </span>
            <Button
              size="icon"
              onClick={() => setWeek((w) => (w + 1 <= 3 ? w + 1 : w))}
              disabled={week === 3}
            >
              <ArrowRightIcon size={16} />
            </Button>
          </div>
        </div>
      </header>
      <div
        ref={scrollContainerRef}
        className="bg-gray-lightest relative flex h-[calc(100vh-95px)] w-full overflow-auto"
      >
        <div className="relative z-[1] mr-2 pl-3 md:pl-5">
          <div className="mt-14">
            {timeIntervals.map((hours) => (
              <div
                key={hours.toISOString()}
                className={twMerge(
                  'text-gray-dark font-mono-chivo relative w-full text-right text-xs',
                  hours.getMinutes() !== 0 && 'opacity-0'
                )}
                style={{
                  height: MINUTES_PER_TIME_FRAGMENT * PIXELS_PER_MINUTE,
                }}
              >
                {format(hours, 'HH:mm')}
              </div>
            ))}
          </div>
        </div>
        <div className="pointer-events-none absolute left-0 top-16 z-[0] w-full">
          {timeIntervals.map((hours) => (
            <div
              key={hours.toISOString()}
              id={`schedule-time-${format(hours, 'HH:mm')}`}
              className={twMerge('absolute w-full overflow-hidden')}
              style={{
                top:
                  (hours.getHours() * 60 + hours.getMinutes()) *
                  PIXELS_PER_MINUTE,
                height: MINUTES_PER_TIME_FRAGMENT * PIXELS_PER_MINUTE,
              }}
            >
              <div
                className={twMerge(
                  'absolute top-0 left-16 h-px w-full bg-slate-200',
                  hours.getMinutes() !== 0 && 'opacity-50'
                )}
              />
            </div>
          ))}
        </div>

        <div className="z-[2] grid h-full flex-1 grid-cols-[repeat(7,minmax(80px,1fr))] gap-[3px]">
          {daysList.map((day, idx) => {
            const isOnWeekend = isWeekend(day)

            return (
              <div
                key={`${idx}`}
                className={twMerge(
                  'space-y-2 py-6',
                  !isOnWeekend && 'bg-slate-200/30'
                )}
              >
                <div className="sticky top-0 z-20 h-8 px-1 text-left leading-none">
                  <h3
                    className={twMerge(
                      'font-medium uppercase text-blue-600',
                      isOnWeekend && 'text-slate-500'
                    )}
                  >
                    {format(day, 'E')}
                  </h3>
                  <p className="font-mono-chivo text-xs text-slate-500">
                    {format(day, 'dd.MM')}
                  </p>
                </div>
                <TimePickerDay
                  day={day}
                  timeBlocks={value?.filter((timeBlock) =>
                    isSameDay(timeBlock.date, day)
                  )}
                  addTimeBlock={addTimeBlock}
                  removeTimeBlock={removeTimeBlock}
                  updateTimeBlock={updateTimeBlock}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type TimePickerDayProps = {
  day: Date
  timeBlocks: TimeBlock[]
  addTimeBlock: (timeBlock: TimeBlock) => void
  updateTimeBlock: (timeBlock: TimeBlock) => void
  removeTimeBlock: (id: string) => void
}

type TimeBlock = {
  id: string
  date: Date
  duration: number
}

const TimePickerDay = ({
  day,
  timeBlocks,
  addTimeBlock,
  removeTimeBlock,
  updateTimeBlock,
}: TimePickerDayProps) => {
  const selfRef = React.useRef<HTMLDivElement>(null)

  return (
    <div
      ref={selfRef}
      className={classNames(
        'relative h-full rounded-xl',
        'transition-all duration-100',
        'hover:bg-slate-400/10'
      )}
      style={{
        height: (TOTAL_HOURS - START_HOUR) * 60 * PIXELS_PER_MINUTE,
      }}
    >
      <div
        className="absolute inset-0 z-[0]"
        onPointerDown={(e) => {
          e.stopPropagation()
          const rect = e.currentTarget.getBoundingClientRect()
          const y = e.clientY - rect.top
          const startMinutes =
            Math.floor(y / PIXELS_PER_MINUTE / MINUTES_PER_TIME_FRAGMENT) *
            MINUTES_PER_TIME_FRAGMENT

          const startDate = addMinutes(day, startMinutes)
          const duration = 15

          addTimeBlock({ id: uuid(), date: startDate, duration })
        }}
      />
      <AnimatePresence>
        {timeBlocks.map((timeBlock) => (
          <TimePickerTimeBlock
            key={timeBlock.id}
            timeBlock={timeBlock}
            otherDayBlocks={timeBlocks}
            onRemove={() => removeTimeBlock(timeBlock.id)}
            onUpdate={(timeBlock) => updateTimeBlock(timeBlock)}
            dayRef={selfRef}
          />
        ))}
      </AnimatePresence>
    </div>
  )
}

const TimePickerTimeBlock = ({
  timeBlock,
  otherDayBlocks,
  onRemove,
  onUpdate,
  dayRef,
}: {
  timeBlock: TimeBlock
  otherDayBlocks: TimeBlock[]
  onRemove: () => void
  onUpdate: (timeBlock: TimeBlock) => void
  dayRef: React.RefObject<HTMLDivElement>
}) => {
  const selfRef = React.useRef<HTMLDivElement>(null)

  const [from, setFrom] = useState<Date>(timeBlock.date)

  const startOfToday = startOfDay(timeBlock.date)

  const [initialDragDistance, setInitialDragDistance] = useState(0)

  const [fromMinutes, setFromMinutes] = React.useState(
    differenceInMinutes(timeBlock.date, startOfToday)
  )

  const [duration, setDuration] = React.useState(timeBlock.duration)

  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)

  const handlePointerMove = React.useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation()
      e.preventDefault()

      if (isResizing) {
        const rect = selfRef.current?.getBoundingClientRect()
        const newMinutes =
          Math.floor(
            Math.floor((e.clientY - (rect?.top ?? 0)) / PIXELS_PER_MINUTE) /
              MINUTES_PER_TIME_FRAGMENT_STEP
          ) * MINUTES_PER_TIME_FRAGMENT_STEP
        if (newMinutes <= TOTAL_HOURS * 60 && newMinutes >= 15) {
          setDuration(newMinutes)
        }
      }
      if (isDragging) {
        const dayRect = dayRef.current?.getBoundingClientRect()

        const moveMinutes =
          Math.floor(
            Math.floor(
              (e.clientY - (dayRect?.top ?? 0) - initialDragDistance) /
                PIXELS_PER_MINUTE
            ) / MINUTES_PER_TIME_FRAGMENT_STEP
          ) * MINUTES_PER_TIME_FRAGMENT_STEP

        if (moveMinutes >= 0 && moveMinutes + duration <= TOTAL_HOURS * 60) {
          setFromMinutes(moveMinutes)
          setFrom(addMinutes(startOfToday, moveMinutes))
        }
      }
    },
    [
      isResizing,
      isDragging,
      dayRef,
      initialDragDistance,
      duration,
      startOfToday,
      setFromMinutes,
      setFrom,
      setDuration,
    ]
  )

  const handlePointerUp = React.useCallback(
    (e: React.PointerEvent) => {
      setIsResizing(false)
      setIsDragging(false)

      selfRef.current?.releasePointerCapture(e.pointerId)
      onUpdate({ ...timeBlock, date: from, duration })
    },
    [duration, from, onUpdate, timeBlock]
  )

  const handleMovePointerDown = React.useCallback(
    (e: React.PointerEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      setIsDragging(true)
      setInitialDragDistance(
        e.clientY - (selfRef.current?.getBoundingClientRect().top ?? 0)
      )

      selfRef.current?.setPointerCapture(e.pointerId)
    },
    []
  )

  const handleResizePointerDown = React.useCallback(
    (e: React.PointerEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()

      setIsResizing(true)
      selfRef.current?.setPointerCapture(e.pointerId)
    },
    []
  )

  return (
    <motion.div
      ref={selfRef}
      className={twMerge(
        'absolute left-0 z-10 w-full p-0.5 hover:z-40',
        (isResizing || isDragging) && 'z-30'
      )}
      style={{
        top: fromMinutes * PIXELS_PER_MINUTE + dayTopPadding,
        height: duration * PIXELS_PER_MINUTE,
      }}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 20,
      }}
    >
      <div
        className={twMerge(
          'transition-all duration-300',
          'group relative flex !h-full w-full items-start overflow-clip rounded-lg bg-blue-200 p-1 ring-1 ring-blue-300',
          (isResizing || isDragging) && 'ring-1 ring-blue-500'
        )}
      >
        <button
          type="button"
          className="absolute inset-0 z-[0] cursor-move"
          aria-label="Move time block"
          onPointerDown={handleMovePointerDown}
          onPointerUp={handlePointerUp}
        />
        <span className="text-xs text-blue-700">
          {format(addHours(from, START_HOUR), 'HH:mm')} -{' '}
          {format(addHours(addMinutes(from, duration), START_HOUR), 'HH:mm')}
        </span>
        <button
          type="button"
          className="z-10 ml-auto rounded-full p-0.5 text-blue-400 transition-all duration-300 hover:text-blue-500"
          onClick={() => onRemove()}
          aria-label="Remove time block"
        >
          <TrashIcon strokeWidth={1} absoluteStrokeWidth size={12} />
        </button>
        <button
          type="button"
          className={twMerge(
            'absolute left-0 bottom-0 z-20 h-2 w-full cursor-s-resize overflow-clip px-1.5 py-0.5 text-white transition-all duration-300'
          )}
          onPointerDown={handleResizePointerDown}
          onPointerUp={handlePointerUp}
          aria-label="Resize time block"
        >
          <span
            className={twMerge(
              'mx-auto block h-0.5 w-6 rounded-full bg-blue-300',
              (isResizing || isDragging) && 'bg-blue-500'
            )}
          ></span>
        </button>
      </div>
    </motion.div>
  )
}
